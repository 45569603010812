import React, { type ElementType } from 'react';

type LabelProps = {
  tagName?: ElementType;
  className?: string;
  children?: React.ReactNode;
};

const Label = ({ tagName, children, className = '', ...props }: LabelProps) => {
  const defaultTagName = 'label';
  const Tag = tagName || defaultTagName;

  return (
    <Tag
      className={`text-sm tracking-normal xl:text-base xl:leading-md ${className}`}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default Label;
