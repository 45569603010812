import { ElementType, PropsWithChildren } from 'react';

type SubheadingProps = {
  tagName?: ElementType;
  className?: string;
};

const Subheading = ({
  tagName,
  children,
  className = '',
  ...props
}: PropsWithChildren<SubheadingProps>) => {
  const defaultTagName = 'div';
  const Tag = tagName || defaultTagName;

  return (
    <Tag
      className={`text-sm leading-md md:text-base md:leading-md xl:text-xl xl:leading-md ${className}`}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default Subheading;
