import cx from 'classnames';
import { BaseButtonType } from 'components/base-button';

type ButtonLoaderProps = {
  type?: BaseButtonType;
  isDarkBackground?: boolean;
};

export default function ButtonLoader({
  type,
  isDarkBackground
}: ButtonLoaderProps): React.ReactNode {
  const isPrimary = type === BaseButtonType.Primary;
  const isSecondary = type === BaseButtonType.Secondary;
  const isTertiary = type === BaseButtonType.Tertiary;

  const loaderClasses = cx(
    'absolute left-0 top-0 size-full rounded-full border-thicker',
    {
      'border-black border-opacity-16':
        isPrimary ||
        (isTertiary && !isDarkBackground) ||
        (isSecondary && isDarkBackground),
      'border-gray-700 border-opacity-100':
        (isSecondary && !isDarkBackground) || (isTertiary && isDarkBackground)
    }
  );
  const loaderClassesTop = cx(
    'absolute left-0 top-0 z-10 size-full rounded-full border-thicker border-transparent'
  );
  return (
    <div className="relative h-5 w-5 animate-spin">
      <div
        className={loaderClassesTop}
        style={{
          borderTopColor:
            (isSecondary && !isDarkBackground) ||
            (isTertiary && isDarkBackground) ||
            isPrimary
              ? 'white'
              : 'black'
        }}
      />
      <div className={loaderClasses} />
    </div>
  );
}
