import { ElementType, PropsWithChildren } from 'react';

type QuoteProps = {
  tagName?: ElementType;
  className?: string;
};

const Quote = ({
  tagName,
  children,
  className = '',
  ...props
}: PropsWithChildren<QuoteProps>) => {
  const defaultTagName = 'div';
  const Tag = tagName || defaultTagName;

  return (
    <Tag className={`font-serif text-sm ${className}`} {...props}>
      {children}
    </Tag>
  );
};

export default Quote;
