import { ElementType, PropsWithChildren } from 'react';

export type HeadingSizes =
  | '3xl'
  | '2xl'
  | 'xl'
  | 'l'
  | 'm'
  | 's'
  | 'xs'
  | 'xxs';

type HeadingProps = {
  tagName?: ElementType;
  className?: string;
  size: HeadingSizes;
};

const headingSizeMap: { [key in HeadingSizes]: string } = {
  '3xl':
    'text-7xl md:text-9xl xl:text-11xl font-bold leading-xs md:leading-xs xl:leading-xs tracking-tighter',
  '2xl':
    'text-5xl md:text-7xl xl:text-9xl font-bold leading-xs md:leading-xs xl:leading-xs tracking-tighter',
  xl: 'text-3xl md:text-5xl xl:text-6xl font-bold tracking-tighter xl:tracking-tight',
  l: 'text-2xl md:text-3xl xl:text-4xl font-bold tracking-normal xl:tracking-tight',
  m: 'text-lg xl:text-2xl font-bold tracking-normal',
  s: 'text-sm xl:text-base font-bold tracking-normal',
  xs: 'text-sm font-bold tracking-normal',
  xxs: 'text-xs font-bold tracking-normal'
};

const defaultTagNameMap: { [key in HeadingSizes]: ElementType } = {
  '3xl': 'h1',
  '2xl': 'h2',
  xl: 'h3',
  l: 'h4',
  m: 'h4',
  s: 'h4',
  xs: 'h4',
  xxs: 'h4'
};

const Heading = ({
  tagName,
  children,
  className = '',
  size,
  ...props
}: PropsWithChildren<HeadingProps>) => {
  const defaultTagName = defaultTagNameMap[size];
  const Tag: ElementType = tagName || defaultTagName;

  return (
    <Tag className={`${headingSizeMap[size]} ${className}`} {...props}>
      {children}
    </Tag>
  );
};

export default Heading;
