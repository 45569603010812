import cx from 'classnames';
import type { PropsWithChildren, JSX } from 'react';

export type TextSizes = 'xl' | 'l' | 'm' | 's';

type TextProps = {
  tagName?: keyof JSX.IntrinsicElements;
  className?: string;
  size: TextSizes;
};

const textSizeMap: { [key in TextSizes]: string } = {
  xl: 'text-xl leading-md',
  l: 'text-base leading-md',
  m: 'text-sm',
  s: 'text-xs'
};

const Text = ({
  tagName,
  children,
  className = '',
  size,
  ...props
}: PropsWithChildren<TextProps>) => {
  const defaultTagName = 'div';
  const Tag = tagName || defaultTagName;

  return (
    <Tag className={cx(textSizeMap[size], className)} {...props}>
      {children}
    </Tag>
  );
};

export default Text;
