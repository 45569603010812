import type { JSX } from 'react';

type CheckIconProps = {
  className?: string;
  variant: 'default' | 'circle-fill' | 'circle' | 'small' | 'thick' | 'white';
  ariaHidden?: boolean;
};

const CheckIcon = (props: CheckIconProps): JSX.Element => {
  const { className, variant, ariaHidden } = props;
  switch (variant) {
    case 'circle-fill':
      return (
        <svg
          className={className || ''}
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5ZM13.5 6.5L7.8125 12.1875C7.6875 12.3125 7.5625 12.3125 7.4375 12.3125C7.3125 12.3125 7.1875 12.25 7.0625 12.1875L4.5 9.625C4.3125 9.4375 4.3125 9.125 4.5 8.875C4.6875 8.625 5 8.6875 5.25 8.875L7.5 11.125L12.75 5.8125C12.9375 5.625 13.25 5.625 13.5 5.8125C13.75 6 13.6875 6.3125 13.5 6.5Z"
            fill="#62BAAA"
          />
        </svg>
      );
    case 'circle':
      return (
        <svg
          className={className || ''}
          width="65"
          height="64"
          viewBox="0 0 65 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.7804 46.5598L50.4204 23.9198L46.7404 20.3198L27.7804 39.2798L18.1804 29.6798L14.5804 33.2798L27.7804 46.5598ZM32.5004 63.9998C28.1324 63.9998 23.9884 63.1518 20.1004 61.4718C16.2124 59.7918 12.8044 57.5038 9.89237 54.5918C6.98038 51.6798 4.69238 48.2878 3.01238 44.3838C1.33238 40.4798 0.484375 36.3518 0.484375 31.9838C0.484375 27.6158 1.33238 23.3918 3.01238 19.5038C4.69238 15.6158 6.98038 12.2238 9.89237 9.34377C12.8044 6.46377 16.1964 4.17577 20.1004 2.49577C24.0044 0.815773 28.1324 -0.0322266 32.5004 -0.0322266C36.8684 -0.0322266 41.0924 0.815773 44.9804 2.49577C48.8684 4.17577 52.2604 6.46377 55.1404 9.34377C58.0204 12.2238 60.3084 15.6158 61.9884 19.5038C63.6684 23.3918 64.5164 27.5518 64.5164 31.9838C64.5164 36.4158 63.6684 40.4958 61.9884 44.3838C60.3084 48.2718 58.0204 51.6798 55.1404 54.5918C52.2604 57.5038 48.8684 59.7918 44.9804 61.4718C41.0924 63.1518 36.9324 63.9998 32.5004 63.9998ZM32.5004 59.1998C40.0684 59.1998 46.5004 56.5438 51.7804 51.2318C57.0604 45.9198 59.7004 39.5038 59.7004 31.9838C59.7004 24.4638 57.0604 17.9838 51.7804 12.7038C46.5004 7.42377 40.0684 4.78377 32.5004 4.78377C24.9324 4.78377 18.5644 7.42377 13.2524 12.7038C7.94038 17.9838 5.28438 24.4158 5.28438 31.9838C5.28438 39.5518 7.94038 45.9198 13.2524 51.2318C18.5644 56.5438 24.9804 59.1998 32.5004 59.1998Z"
            fill="#121212"
          />
        </svg>
      );
    case 'small':
      return (
        <svg
          className={className || ''}
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3334 4.5L6.00008 11.8333L2.66675 8.5"
            stroke="#2763FF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'thick':
      return (
        <svg
          className={className || ''}
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6668 5.5L7.50016 14.6667L3.3335 10.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'white':
      return (
        <svg
          className={className || ''}
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#F6F6F6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg
          className={className || ''}
          aria-hidden={ariaHidden}
          viewBox="0 0 18 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 1L6 12L1 7"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
export default CheckIcon;
