import { ElementType, PropsWithChildren } from 'react';

export type MicrocopySizes = 'xs' | 'm' | 's';

type MicrocopyProps = {
  tagName?: ElementType;
  className?: string;
  size: MicrocopySizes;
};

const microcopySizeMap: { [key in MicrocopySizes]: string } = {
  m: 'text-sm font-medium',
  s: 'text-xs',
  xs: 'text-xxs font-bold leading-xs'
};

const Microcopy = ({
  tagName,
  children,
  className = '',
  size,
  ...props
}: PropsWithChildren<MicrocopyProps>) => {
  const defaultTagName = 'div';
  const Tag = tagName || defaultTagName;

  return (
    <Tag className={`${microcopySizeMap[size]} ${className}`} {...props}>
      {children}
    </Tag>
  );
};

export default Microcopy;
