import cx from 'classnames';
import ButtonLoader from 'components/button-loader';
import { useDarkMode } from 'components/theme/DarkModeProvider';
import { Text } from 'components/typography';
import { AnimatePresence, HTMLMotionProps, motion } from 'motion/react';

import CheckIcon from 'icons/CheckIcon';

export enum BaseButtonType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY',
  GhostLink = 'GHOST_LINK',
  GhostLinkMuted = 'GHOST_LINK_MUTED'
}

export enum IconPositionType {
  Leading = 'LEADING',
  Trailing = 'TRAILING'
}

export type BaseButtonProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: React.FC<unknown>;
  iconPosition?: IconPositionType;
  interactive?: boolean;
  loading?: boolean;
  small?: boolean;
  success?: boolean;
  text?: string | React.ReactNode;
  variant?: BaseButtonType;
  isDarkBackground?: boolean;
  style?: React.CSSProperties;
};

interface MotionDivProps extends HTMLMotionProps<'div'> {
  children: React.ReactNode;
  className?: string;
}

const MotionDiv = motion.div as React.ComponentType<MotionDivProps>;

function BaseButton({
  children,
  className,
  disabled,
  fullWidth,
  icon,
  iconPosition = IconPositionType.Leading,
  interactive,
  loading,
  small,
  success,
  text,
  isDarkBackground,
  variant = BaseButtonType.Primary,
  ...props
}: BaseButtonProps): React.ReactNode {
  const { isDarkMode } = useDarkMode();

  const isPrimary = variant === BaseButtonType.Primary;
  const isSecondary = variant === BaseButtonType.Secondary;
  const isTertiary = variant === BaseButtonType.Tertiary;
  const isGhostLink = variant === BaseButtonType.GhostLink;
  const isGhostLinkMuted = variant === BaseButtonType.GhostLinkMuted;

  const isLeadingIcon = iconPosition === IconPositionType.Leading;
  const isTrailingIcon = iconPosition === IconPositionType.Trailing;

  const buttonClasses = cx(
    'relative inline-flex min-w-button select-none items-center justify-center rounded-sm py-3 transition-colors duration-1000',
    className,
    {
      'min-h-button-sm px-4': small,
      'min-h-button px-5': !small,
      'cursor-auto opacity-50': disabled,
      'w-full justify-center': fullWidth,

      'bg-red-700 text-white': isPrimary && !disabled,
      'bg-gray-300 text-gray-500':
        (isPrimary && disabled) ||
        (isSecondary && disabled && !isDarkBackground),
      'hover:bg-red-900': isPrimary && interactive && !disabled,

      'bg-black text-white': isSecondary && !disabled && !isDarkBackground,
      'bg-white text-black': isSecondary && !disabled && isDarkBackground,
      'bg-gray-500 text-gray-900': isSecondary && disabled && isDarkBackground,
      'hover:bg-gray-900': isSecondary && interactive && !isDarkBackground,
      'hover:bg-gray-300': isSecondary && interactive && isDarkBackground,

      'bg-white': isTertiary && !disabled && !isDarkBackground && !isDarkMode,
      'dark:border-[#FFFFFF80] dark:text-white':
        isTertiary && !disabled && !isDarkBackground && isDarkMode,
      'border-thin border-black border-opacity-16 dark:bg-none':
        isTertiary && !disabled && !isDarkBackground,
      'border-thin border-gray-500 bg-black text-white':
        isTertiary && !disabled && isDarkBackground,
      'border-thin border-black border-opacity-16 text-gray-500':
        isTertiary && disabled && !isDarkBackground,
      'border-thin border-gray-900 text-gray-500':
        isTertiary && disabled && isDarkBackground,
      'hover:border-gray-500': isTertiary && interactive && !isDarkBackground,
      'hover:border-white': isTertiary && interactive && isDarkBackground,

      'bg-transparent text-blue-500 hover:bg-gray-300':
        isGhostLink && !disabled,
      'bg-transparent text-black hover:bg-gray-300':
        isGhostLinkMuted && !disabled,
      'hover:bg-gray-100 bg-transparent text-blue-300': isGhostLink && disabled,
      'hover:bg-gray-100 bg-transparent text-black':
        isGhostLinkMuted && disabled
    }
  );

  const iconClasses = cx(
    'h-4 w-4 shrink-0 scale-125 transition duration-1000',
    {
      'origin-left': isLeadingIcon,
      'origin-right': isTrailingIcon,
      'mr-3': isLeadingIcon && small,
      'ml-3': isTrailingIcon && small,
      'mr-4': isLeadingIcon && !small,
      'ml-4': isTrailingIcon && !small
    }
  );
  const Icon = icon as React.FC<unknown>;

  const buttonContentClass = cx(
    'inline-flex items-center transition-opacity duration-300',
    {
      'opacity-100': !loading && !success,
      'opacity-0': loading || success,
      'flex-row-reverse': icon && iconPosition === IconPositionType.Trailing
    }
  );

  const statusClasses = cx(
    'absolute left-0 top-0 flex size-full items-center justify-center'
  );

  return (
    <div className={buttonClasses} {...props}>
      <div className={buttonContentClass}>
        {icon && (
          <span className={iconClasses}>
            <Icon />
          </span>
        )}
        {text && !children && (
          <Text size={small ? 's' : 'm'} className="font-bold">
            {text}
          </Text>
        )}
        {children}
      </div>
      <AnimatePresence>
        {loading && (
          <MotionDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={statusClasses}
          >
            <ButtonLoader type={variant} isDarkBackground={isDarkBackground} />
          </MotionDiv>
        )}
        {success && (
          <MotionDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={statusClasses}
          >
            <CheckIcon variant="thick" className="h-5 w-5" />
          </MotionDiv>
        )}
      </AnimatePresence>
    </div>
  );
}

export default BaseButton;
